import React, { useState } from 'react'
import ImageUploading from "react-images-uploading";
import Cropper from "react-easy-crop";
import 'react-image-upload/dist/index.css'
import Logo from "../asset/dsci-logo-color 1.svg"
import ReactImageUploading from 'react-images-uploading'
import { cropImage } from "./cropUtils";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { FaArrowLeft } from "react-icons/fa6";
const ImageUploadingButton = ({ value, onChange, ...props }) => {
  return (
    <ImageUploading value={value} onChange={onChange}>
      {({ onImageUpload, onImageUpdate }) => (
        <button
          className="uppercase w-[150px]  flex  items-center justify-center gap-3 rounded-lg shadow-md  ease-in duration-300  text-[12px] md:text-[16px]  hover:shadow-none hover:bg-[#b9992f] tracking-wider py-[8px] px-[24px] md:py-[12px] md:px-[24px] text-[#272727] bg-[#fcd142] "
          onClick={value ? onImageUpload : () => onImageUpdate(0)}
          {...props}
        >
          Upload
        </button>
      )}
    </ImageUploading>
  );
};
const ImageCropper = ({
  open,
  image,
  onComplete,
  containerStyle,
  ...props
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Crop Image</DialogTitle>

      <DialogContent>
        <div style={containerStyle}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={.8}
            onCropChange={setCrop}
            onCropComplete={(_, croppedAreaPixels) => {
              setCroppedAreaPixels(croppedAreaPixels);
            }}
            onZoomChange={setZoom}
            {...props}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={() =>
            onComplete(cropImage(image, croppedAreaPixels, console.log))
          }
        >
          Finish
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const FormSection = ({cardDetails,setCardDetails,showCard,setShowCard,croppedImage,setCroppedImage}) => {
  const [image, setImage] = useState([]);
 
  const [dialogOpen, setDialogOpen] = useState(false);
    
  return (
    <div >
        
            <div className='flex  items-start lg:px-16 lg:pt-0 pt-0 px-3  justify-center flex-col'>
            <a className='flex items-center justify-start gap-3 text-white' href='https://www.dsci.in/event/aiss-2024/' ><FaArrowLeft className='text-[2rem] my-6 text-white' /> Go to Website </a>
              <img className='pt-2 w-[40%] pb-6' src={Logo} />
              
                <h3 className=' text-white text-[1rem] lg:text-[1.5rem]'>Enter your details for a personalised AISS 2024 frame</h3>
                <div className='mt-8'>
                    <input value={cardDetails.name} onChange={(e)=>setCardDetails({...cardDetails,name:e.target.value})} type="text" placeholder='Enter Name' className='lg:w-[550px] w-full my-2 rounded-lg h-[65px] px-3 py-4 text-white placeholder:tect-gray-300 bg-[#e3e3e340] ' />
                    <input value={cardDetails.designation} onChange={(e)=>setCardDetails({...cardDetails,designation:e.target.value})} type="text" placeholder='Designation, Company' className='lg:w-[550px] w-full h-[65px] my-2 rounded-lg px-3 py-4 text-white placeholder:tect-gray-300 bg-[#e3e3e340] ' />
                    <select  onChange={(e)=>setCardDetails({...cardDetails,type:e.target.value})} className='lg:w-[550px] my-2 round rounded-lg px-3 py-4 h-[65px] text-white w-full placeholder:text-gray-300 bg-[#e3e3e340] '>
                        <option style={{color:"black"}} defaultChecked value="">Click to Select an Option</option>
                        <option style={{color:"black"}}  value="I'm Attending">I'm Attending</option>
                        <option style={{color:"black"}} value="I'm Speaking">I'm Speaking</option>
                        <option style={{color:"black"}} value="I'm Sponsor">I'm A Sponsor</option>
                    </select>
                    <ImageUploadingButton
        value={image}
        onChange={(newImage) => {
          setDialogOpen(true);
          setImage(newImage);
        }}
      />
      <ImageCropper
        open={dialogOpen}
        image={image.length > 0 && image[0].dataURL}
        onComplete={(imagePromisse) => {
          imagePromisse.then((image) => {
            setCroppedImage(image);
            setDialogOpen(false);
        
           setCardDetails({...cardDetails,image:image})
          });
        }}
        containerStyle={{
          position: "relative",
          width: "100%",
          height: 300,
          background: "#333"
        }}
      />
      <img className='w-[80px] mt-2' src={croppedImage} />
    <button onClick={()=>{
        if(cardDetails.name.length!==0 && cardDetails.designation.length !==0 && cardDetails.type.length !==0 && cardDetails.image.length !==0 ){
            setShowCard(true)
        }
        else{
            alert("Please fill all the details")
        }
    }} className="uppercase w-full lg:w-[550px] flex mt-6 items-center justify-center gap-3 rounded-lg shadow-md  ease-in duration-300  text-[12px] md:text-[16px]  hover:shadow-none hover:bg-[#b9992f] tracking-wider py-[8px] px-[24px] md:py-[12px] md:px-[24px] text-[#272727] bg-[#fcd142] ">Submit</button>
                </div>
            </div>
        
    </div>
  )
}

export default FormSection