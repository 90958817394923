import React,{ useRef } from 'react'
import generatePDF, { Resolution, Margin } from 'react-to-pdf';  
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import Logo from "../asset/dsci-logo-color 1.svg"
import BPMLogo from "../asset/bpmwithtext.png"
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image-more';
import { toJpeg } from 'html-to-image';
const FrameSection = ({cardDetails,setShowCard,croppedImage,setCardDetails,setCroppedImage}) => {
  const componentRef = useRef();
  const downloadHighResImage = async () => {
    if (componentRef.current) {
      try {
        const dataUrl = await toJpeg(componentRef.current, {
          quality: 1, // Highest quality for better resolution
          
        });

        const link = document.createElement('a');
        link.download = 'card-image.jpeg';
        link.href = dataUrl;
        link.click();
      } catch (error) {
        console.error('Failed to download image:', error);
      }
    }
  };
 
  return (
    <div className='lg:pt-[8px] overflow-scroll lg:overflow-auto pt-12 px-2 lg:px-6'>
      <div ref={componentRef} id="content-id" className='frameSection border-[1px] border-gray-100 w-[350px] h-[380px] lg:w-[600px] lg:h-[600px] relative' >
       
       
            <h6 className='absolute text-[.9rem]  lg:text-[1.7rem] font-[600] lg:top-[16.8%] top-[16.8%] right-[5.5%] lg:right-[8%]' >{cardDetails.type}</h6>
            
            <div className='flex-col absolute lg:w-[200px]  lg:top-[29%] top-[28%]  lg:right-[5%] right-[2%]  flex items-center justify-center w-[120px]  ' >
            <img className=' px-1 pt-2 lg:px-2 lg:py-2 w-[120px] h-[150px]  lg:h-[205px] lg:w-[205px]' src={croppedImage} />
            <h3 className={`${cardDetails?.name?.length  >= 15 ? 'lg:text-[.7rem] text-[.5rem] ':'lg:text-[1rem] text-[.7rem]'} break-words w-[120px] lg:w-[200px] mt-2  lg:mt-8 pl-1 lg:pl-3 capitalize  font-[600] `}>{cardDetails.name}</h3>
            <h3 className={`${cardDetails.designation.length  >= 20 ? 'lg:text-[.6rem]  text-[.5rem]':'lg:text-[.9rem] text-[.7rem]'} break-words w-[120px] lg:w-[200px] max-w-[200px] pl-1  lg:pl-3 `}>{cardDetails.designation}</h3>
            </div>

      </div>
        
        <div className='flex mt-4 lg:pl-2 items-center justify-start gap-6'>
            <button onClick={()=>{
               setCardDetails({
                  name:"",
                  designation:"",
                  type:"",
               })
               setCroppedImage("")
               setShowCard(false)}} style={{width:"200px"}} className='bg-gray-400 uppercase w-[150px]  gap-3 rounded-lg shadow-md  ease-in duration-300  text-[10px] md:text-[16px]  hover:shadow-none hover:bg-[#272727] tracking-wider py-[8px] px-[12px]  md:py-[12px] md:px-[24px] text-[#272727] '>Start Again</button>
            <button style={{width:"200px"}}  onClick={downloadHighResImage} className="uppercase w-[150px]  flex  items-center justify-center gap-3 rounded-lg shadow-md  ease-in duration-300  text-[12px] md:text-[16px]  hover:shadow-none hover:bg-[#b9992f] tracking-wider py-[8px] px-[24px] md:py-[12px] md:px-[24px] text-[#272727] bg-[#fcd142]">Download</button>
        </div>
    </div>
  )
}

export default FrameSection